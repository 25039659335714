import React, { ReactChild } from "react";
import get from "lodash/get";
import { locale } from "./baseComponents";
import { WmkLink } from "wmk-link";
import { RichTextEntryHyperlink, RichTextNode } from "wmk-rich-text";
// import { buildSlug } from "../vars/ThemeOptions";
// import { locale } from "./baseComponents";

const RenderEntry = {
  // builderPages: ({ fields, children }) => {
  //   const slugs = {
  //     slug: fields.slug ? fields.slug[locale] : "/",
  //     parentPageSlug: fields.parentPageSlug
  //       ? fields.parentPageSlug[locale]
  //       : null,
  //     subParentPageSlug: fields.subParentPageSlug
  //       ? fields.subParentPageSlug[locale]
  //       : null,
  //   };
  //   const path = buildSlug(slugs);
  //   //const _children = children.length ? children.join() : children;
  //   //console.log(children,_children)
  //   return (
  //     <WmkLink to={path} className="inline">
  //       {children.map((child) => child)}
  //     </WmkLink>
  //   );
  // },
  // menuItem: ({ fields, children }) => {
  //   const entry = fields.entry[locale].fields;
  //   const _entry = {
  //     slug: entry.slug[locale],
  //     parentPageSlug: entry.parentPageSlug[locale],
  //     subParentPageSlug: entry.subParentPageSlug[locale],
  //   };
  //   return (
  //     <WmkLink to={buildSlug(_entry)} className="inline">
  //       {children}
  //     </WmkLink>
  //   );
  // },
  blogs: ({ fields, children }: { fields:RichTextEntryHyperlink, children: ReactChild }) => (
    <WmkLink to={`/blog/` + get(fields, `slug.${locale}`)}>{children}</WmkLink>
  ),
};

const inlineEntryHyperlink = (node: RichTextNode, children: ReactChild) => {
  const fields = node.data.target.sys;
  const contentType =
    node.data.target.sys.contentType && node.data.target.sys.contentType.sys.id;
  // console.log(contentType, fields);
  const Jsx: Function = RenderEntry[contentType as keyof Object];

  return Jsx ? (
    <Jsx fields={fields}>{children}</Jsx>
  ) : (
    <em className="hyperlink-error error">
      {children}
      {console.log("inline hyperlink " + contentType + " not set")}
    </em>
  );
};

export default inlineEntryHyperlink;
