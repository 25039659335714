import React from "react";
// import BulletDrop from "../../components/flexible/blocks/BlockBody/Bullets/BulletDrop";
import { Row, Col } from "react-bootstrap";
import { Paragraph } from "./elements/Paragraph";
import styled from "styled-components";
import { RichTextBlock } from "wmk-rich-text";

const NestedLiWrap = styled.li`
  list-style: none;
`;

export const NestedComps = {
  paragraph: ({ content }: { content: RichTextBlock[] }) => {
    return <Paragraph content={content} />;
  },
  "unordered-list": ({ content }: { content: RichTextBlock[] }) => {
    return (
      <ul>
        {content.map((li, i) => {
          return (
            <NestedLiWrap key={li.nodeType + i}>
              {li.content.map((p, j) => {
                return "content" in p ? (
                  <Paragraph
                    key={li.nodeType + i + p.nodeType + j}
                    content={p.content}
                  />
                ) : (
                  <></>
                );
              })}
            </NestedLiWrap>
          );
        })}
      </ul>
    );
  },
};

export const BaseComps = {
  paragraph: Paragraph,
  "list-item": ({ content }: { content: RichTextBlock[] }) => {
    // console.log("list-item:", content);
    return (
      <li>
        <Row>
          {/* <Col xs="auto" sm="auto">
            <BulletDrop />
          </Col> */}
          <Col>
            {content.map((el, i) => {
              // console.log("el", el)
              // let JSX = () => <div>shit</div>
              // switch(true){
              //     case el.nodeType === 'paragraph':
              const Jsx: Function = NestedComps[el.nodeType as keyof object];
              //         break;
              // }
              //console.log(JSX,el)
              return Jsx ? (
                <Jsx key={el.nodeType + i} content={el.content} />
              ) : (
                <em className="error">
                  Nested list-item node error: {el.nodeType}
                </em>
              );
            })}
          </Col>
        </Row>
      </li>
    );
  },
};

export const locale = "en-US";
