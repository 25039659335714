import React from "react";
import { RichTextNode } from "wmk-rich-text";
// import { locale } from "./baseComponents";
//import { Button } from "wmk-lib";

const RenderAsset = {
  image: ({
    fields,
  }: {
    fields: {
      file: {
        contentType: string;
        fileName: string;
        url: string;
      };
      title?: string;
      description?: string;
    };
  }) => {
    const { title, file, description } = fields;
    const imgUrl = file ? file.url : "";
    const imgAlt = title ? title : "";
    const desc = description ? description : false;
    return (
      <figure className="embedded-image-wrap">
        <img className="embedded-image" src={imgUrl} alt={imgAlt} />
        {desc && (
          <figcaption className="embedded-image-description">{desc}</figcaption>
        )}
      </figure>
    );
  },
  // application: ({ fields }) => {
  //   const title = fields.title[locale];
  //   const url = fields.file[locale].url;
  //   return (
  //     <div className="embedded-file">
  //       <Button to={url} target="blank">
  //         {title}
  //       </Button>
  //     </div>
  //   );
  // },
};

const blockAsset = (node: RichTextNode) => {
  // const fields = node.data.target.fields;
  const fields = node.reference.data;
  const { contentType } = fields?.file ? fields?.file : { contentType: null };
  const _type = contentType?.split("/")[0];
  const Jsx: Function = RenderAsset[_type as keyof object];
  return Jsx ? (
    <Jsx fields={fields} />
  ) : (
    <em className="error">error with embedded asset block: {_type}</em>
  );
};

export default blockAsset;
