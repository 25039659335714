import { INLINES, BLOCKS } from "@contentful/rich-text-types";
import { ReactChild } from "react";
import { RichTextNode } from "wmk-rich-text";
import blockAsset from "./blockAsset";
// import { RenderEntry, blockEntry } from "./blockEntry/blockEntry";
import blockHeading2 from "./blockHeading2";
import blockUnorderedList from "./blockUnorderedList";
import inlineAssetHyperlink from "./inlineAssetHyperlink";
import inlineEntryHyperlink from "./inlineEntryHyperlink";

const options = {
  renderNode: {
    [BLOCKS.EMBEDDED_ASSET]: (node: RichTextNode) => blockAsset(node),
    // [BLOCKS.EMBEDDED_ENTRY]: (node: RichTextNode) => {
    //   console.log("node", node)
    //   const entry = new EmbeddedBlock(node, RenderEntry);
    //   return entry.render();
    // },
    [BLOCKS.HEADING_2]: (node: RichTextNode, children: ReactChild) =>
      blockHeading2(node, children),
    [BLOCKS.UL_LIST]: (node: RichTextNode) => blockUnorderedList(node),
    [INLINES.ASSET_HYPERLINK]: (node: RichTextNode, children: ReactChild) =>
      inlineAssetHyperlink(node, children),
    [INLINES.ENTRY_HYPERLINK]: (node: RichTextNode, children: ReactChild) =>
      inlineEntryHyperlink(node, children),
  },
};

export default options;
