import React, { ReactChild } from "react";
import { WmkLink } from "wmk-link";
import { RichTextNode } from "wmk-rich-text";
import { locale } from "./baseComponents";

const inlineAssetHyperlink = (node: RichTextNode, children: ReactChild) => {
  const { nodeType, data } = node;
  const url = data?.target?.fields?.file[locale]?.url;
  let Jsx = null;
  switch (nodeType) {
    case "asset-hyperlink":
      Jsx = ({ children }: { children: ReactChild }) => (
        <WmkLink to={url} target="self" className="inline">
          {children}
        </WmkLink>
      );
      break;
    default:
      console.log(nodeType + " not set");
  }
  return <Jsx>{children}</Jsx>;
};

export default inlineAssetHyperlink;
