import React from "react";
import { RichTextNode } from "wmk-rich-text";
import { BaseComps } from "./baseComponents";

const blockUnorderedList = (node: RichTextNode) => {
  const { content } = node;
  return (
    content && (
      <ul style={{ maxWidth: "680px", margin: "auto" }}>
        {content.map((li, i) => {
          // console.log("li", li);
          const Jsx: Function = BaseComps[li.nodeType as keyof object];
          // console.log("Jsx", Jsx)
          return Jsx ? (
            <Jsx
              content={"content" in li ? li.content : li.value}
              key={li.nodeType + i + "-key"}
            />
          ) : (
            <em className="error">Error on list-item node: {li.nodeType}</em>
          );
        })}
      </ul>
    )
  );
};

export default blockUnorderedList;
