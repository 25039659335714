import React, { ReactChild } from "react";
// import { WmkLink } from "wmk-link";
import { RichTextBlock } from "wmk-rich-text";
//import { buildSlug } from "../../vars/ThemeOptions";
// import { locale } from "../baseComponents";
// import { WmkLink } from "wmk-lib";

const ApplyMarks = {
  bold: ({ children }: { children: ReactChild }) => {
    return <strong>{children}</strong>;
  },
  italic: ({ children }: { children: ReactChild }) => {
    return <em>{children}</em>;
  },
};

export const RenderNode = {
  text: ({ node }: { node: RichTextBlock["content"][0] }) => {
    // console.log("blocknode", node);
    let marks: Function[] = [];
    if ("marks" in node) {
      node.marks.forEach((mark) => {
        if (ApplyMarks[mark.type as keyof object])
          marks.push(ApplyMarks[mark.type as keyof object]);
      });
    }
    return marks.length
      ? marks.map((Mark) => {
          const randomKey =
            Math.random()
              .toString(36)
              .substring(2, 15) +
            Math.random()
              .toString(36)
              .substring(2, 15);
          return (
            <Mark key={randomKey}>{"value" in node ? node.value : ""}</Mark>
          );
        })
      : "value" in node
      ? node.value
      : "";
  },
  // "entry-hyperlink": ({ node }) => {
  //   const { fields } = node.data.target;
  //   const slugs = {
  //     slug: fields.slug ? fields.slug[locale] : "/",
  //     parentPageSlug: fields.parentPageSlug
  //       ? fields.parentPageSlug[locale]
  //       : null,
  //     subParentPageSlug: fields.subParentPageSlug
  //       ? fields.subParentPageSlug[locale]
  //       : null,
  //   };
  //   const path = buildSlug(slugs);
  //   //console.log(fields);
  //   //const title = fields.title ? fields.title[locale] : <em>title error!</em>
  //   return (
  //     <WmkLink
  //       to={path}
  //       className="inline"
  //       label={"Read more about " + fields.title[locale]}
  //     >
  //       {node.content.map((text) => text.value)}
  //     </WmkLink>
  //   );
  // },
  // hyperlink: ({ node }) => {
  //   console.log("hyperlink node", node, node.length);
  //   const thisNode = node.length ? node[0] : node;
  //   //const JSX = RenderNode[node.nodeType];
  //   //console.log(thisNode)
  //   const content = thisNode.content[0].value;
  //   return (
  //     <WmkLink to={thisNode.data.uri} target="blank" className="inline">
  //       {content}
  //     </WmkLink>
  //   );
  // },
  // "asset-hyperlink": ({ node }) => {
  //   console.log("asset hyper-link node", node);
  //   const thisNode = node.length ? node[0] : node;
  //   const content = thisNode.content[0].value;
  //   return (
  //     <WmkLink
  //       to={thisNode.data.target.fields.file[locale].url}
  //       target="blank"
  //       className="inline"
  //     >
  //       {content}
  //     </WmkLink>
  //   );
  // },
};

export const Paragraph = ({
  content,
}: {
  content: RichTextBlock["content"];
}) => (
  <p>
    {/* {console.log("content", content)} */}
    {content.map((p) => {
      const JSX: Function = RenderNode[p.nodeType as keyof object];
      //console.log(p.nodeType);
      const randomKey =
        Math.random()
          .toString(36)
          .substring(2, 15) +
        Math.random()
          .toString(36)
          .substring(2, 15);
      return JSX ? (
        <JSX node={p} key={randomKey} />
      ) : (
        <em className="error">Error on: {p.nodeType} in Paragraph</em>
      );
    })}
  </p>
);
